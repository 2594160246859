:root,
body {
    --primary-color: black;
    --footer-bar-bg-color: #1E1E1E;
    --input-text-bg-color: #4c4b4b;
    --input-text-color: white;
    --main-header-logo-left: url("./../images/global/logo-light.png");
    --body-background-image: url("./../images/global/background-light.jpg");
}

:root,
body.dark {
    --primary-color: white;
    --footer-bar-bg-color: #1E1E1E;
    --input-text-bg-color: #4c4b4b;
    --input-text-color: white;
    --main-header-logo-left: url("./../images/global/logo-dark.png");
    --body-background-image: url("./../images/global/background-dark.jpg");
}

html,
body {
    max-width: 100% !important;
    min-height: 100dvh !important;
    overflow-x: hidden !important;
    touch-action: none;

    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    background-repeat: no-repeat;
    background-size: cover;

    color: var(--primary-color) !important;
    background-image: var(--body-background-image) !important;
}

body::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}