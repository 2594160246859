/* You can add global styles to this file, and also import other style files */
@import url('./assets/css/main-v1.css');
@import 'swiper/css';
@import 'swiper/css/bundle';

div.p-toast .p-toast-message.p-toast-message-error,
div.p-toast .p-toast-message.p-toast-message-info,
div.p-toast .p-toast-message.p-toast-message-warn,
div.p-toast .p-toast-message.p-toast-message-success {
  background: linear-gradient(to right, #2a2e2d, #4c4e4a) !important;
  color: #fff !important;
  box-shadow: 0 0 10px 5px #48abe0;
}
